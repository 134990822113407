import React from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";

class CategoryCard extends React.Component {
  render() {
    const { name, img, description, altText, pathName } = this.props;
    return (
      <Col xs={6} sm={6} md={4} lg={4} xl={3} xxl={2}>
        <Link
          to={{
            pathname: `${pathName}`,
            params: {
              filters: { category: name },
            },
          }}
        >
          <Card className="experience-image d-none d-md-block" style={{ margin: "1rem 0.5rem 0 0.25rem !important" }}>
            <Card.Img variant="top" className="img-fit-category" src={img} alt={altText} />
            <Card.ImgOverlay>
              <Card.Footer className="tag-bottom experience-tag2">
                <p className="category-header-small p-0">{name}</p>
                <p className="category-text-small extra px-2">{description}</p>
              </Card.Footer>
            </Card.ImgOverlay>
          </Card>
          <Card className="experience-image d-block d-md-none" style={{ margin: "1rem 0.5rem 0 0.25rem !important" }}>
            <Card.Img variant="top" className="" src={img} alt={altText} />
            <Card.ImgOverlay>
              <Card.Footer className="tag-bottom experience-tag2">
                <p className="category-header-small p-0">{name}</p>
                <p className="category-text-small extra px-2">{description}</p>
              </Card.Footer>
            </Card.ImgOverlay>
          </Card>
        </Link>
      </Col>
    );
  }
}

export default CategoryCard;
