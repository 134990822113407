import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Hub } from "@aws-amplify/core";
import "normalize.css";
import "src/styles/SearchResults.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import "src/styles/FooterPages.css";
import "src/styles/ExperienceCard.css";
import "src/styles/ExperienceDetails.css";
import "src/styles/InteriorPages.css";
import "src/styles/Style.css";
import "src/styles/Buttons.css";
import "src/styles/ExperiencePages.css";
import Loading from "src/components/Loading";
import LandingPage from "./pages/LandingPage";
import { library } from "@fortawesome/fontawesome-svg-core";
import Maintenance from "src/pages/Maintenance";
import Posts from "src/pages/blog/Posts";
import Post from "src/pages/blog/Post";
import { faStripeS, faFacebookF, faTwitter, faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import {
  faTimesCircle,
  faCheckCircle,
  faComments,
  faPlus,
  faUserCircle,
  faMoneyBill,
  faCheck,
  faTimes,
  faMapMarkedAlt,
  faSlidersH,
  faMapMarkerAlt,
  faTicketAlt,
  faPeopleArrows,
  faPlusSquare,
  faUserEdit,
  faPlay,
  faPause,
  faInfoCircle,
  faRecordVinyl,
  faArrowRight,
  faEnvelopeSquare,
  faUserSecret,
  faBars,
  faSignInAlt,
  faCaretDown,
  faSearch,
  faThumbsUp,
  faUserPlus,
  faQuestionCircle,
  faBookOpen,
  faHeart,
  faHighlighter,
  faShoppingBag,
  faWheelchair,
  faChild,
  faEdit,
  faHiking,
  faLightbulb,
  faUserFriends,
  faList,
  faUser,
  faBookmark,
  faIdBadge,
  faStar,
  faThumbsDown,
  faCommentDots,
  faExternalLinkAlt,
  faCrosshairs,
  faMountain,
  faEnvelope,
  faHeading,
  faHome,
  faWrench,
  faHandshake,
  faCalendarDay,
  faArrowAltCircleRight,
  faExternalLinkSquareAlt,
  faEye,
  faEyeSlash,
  faSortNumericUp,
  faDotCircle,
  faDollarSign,
  faCog,
  faImages,
  faCogs,
  faFlag,
  faFile,
  faFileAlt,
  faAddressCard,
  faRedo,
  faMailBulk,
  faTachometerAlt,
  faUsers,
  faBiking,
  faTags,
} from "@fortawesome/free-solid-svg-icons";
library.add(
  faUserCircle,
  faFile,
  faDollarSign,
  faComments,
  faTachometerAlt,
  faUsers,
  faEdit,
  faMailBulk,
  faBiking,
  faRedo,
  faImages,
  faHeading,
  faSortNumericUp,
  faFileAlt,
  faTimesCircle,
  faCog,
  faPlus,
  faFlag,
  faMapMarkedAlt,
  faCheckCircle,
  faSlidersH,
  faMapMarkerAlt,
  faMoneyBill,
  faCheck,
  faCalendarDay,
  faTimes,
  faPeopleArrows,
  faPlusSquare,
  faUserEdit,
  faPlay,
  faPause,
  faInfoCircle,
  faRecordVinyl,
  faArrowRight,
  faEnvelopeSquare,
  faUserSecret,
  faBars,
  faSignInAlt,
  faCaretDown,
  faSearch,
  faThumbsUp,
  faUserPlus,
  faQuestionCircle,
  faBookOpen,
  faHeart,
  faHighlighter,
  faShoppingBag,
  faWheelchair,
  faChild,
  faHiking,
  faLightbulb,
  faUserFriends,
  faList,
  faUser,
  faBookmark,
  faIdBadge,
  faStar,
  faThumbsDown,
  faTicketAlt,
  faCommentDots,
  faExternalLinkAlt,
  faStripeS,
  faCrosshairs,
  faMountain,
  faEnvelope,
  faHome,
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedinIn,
  faWrench,
  faHandshake,
  faArrowAltCircleRight,
  faExternalLinkSquareAlt,
  faEye,
  faEyeSlash,
  faDotCircle,
  faAddressCard,
  faCogs,
  faTags
);

// let body = document.querySelector("body");

/**
 * Check if running production to disable contaxt menu and enable Sentry
 */
if (process.env.NODE_ENV === "production" && window.location.origin === "https://www.scouted.com") {
  // if (body && !window.location.origin.includes("localhost")) {
  //   body.addEventListener("contextmenu", (e) => e.preventDefault());
  // }
  Sentry.init({
    dsn: "https://8d96a8b1998b46bf8dd6e9634337a75d@o510885.ingest.sentry.io/5857747",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

// window.LOG_LEVEL = "DEBUG";
const BookAnExperienceHowItWorks = React.lazy(() => import("src/pages/content/BookAnExperienceHowItWorks"));
const BeAScoutHowItWorks = React.lazy(() => import("src/pages/content/BeAScoutHowItWorks"));
const BeAScoutPurchaseConfirmation = React.lazy(() => import("src/pages/payments/BeAScoutPurchaseConfirmation"));
const BusinessPurchaseConfirmation = React.lazy(() => import("src/pages/payments/BusinessPurchaseConfirmation"));
const BeAScoutPurchaseOptions = React.lazy(() => import("src/pages/content/BeAScoutPurchaseOptions"));
const BusinessPurchaseOptions = React.lazy(() => import("src/pages/content/BusinessPurchaseOptions"));
const BeAScoutPurchaseFeatures = React.lazy(() => import("src/pages/content/BeAScoutPurchaseFeatures"));
const BusinessPurchaseFeatures = React.lazy(() => import("src/pages/content/BusinessPurchaseFeatures"));
const BeAScoutFaq = React.lazy(() => import("src/pages/content/BeAScoutFaq"));
const BeAScoutAffiliate = React.lazy(() => import("src/pages/content/BeAScoutAffiliate"));
const BusinessPartnershipProgram = React.lazy(() => import("src/pages/content/BusinessPartnershipProgram"));
const BookingLedger = React.lazy(() => import("src/pages/user/BookingLedger"));
const Error = React.lazy(() => import("src/pages/error/Error"));
const NotFound = React.lazy(() => import("src/pages/error/NotFound"));
const ThirdPartyError = React.lazy(() => import("src/pages/error/ThirdPartyError"));
const AboutScouted = React.lazy(() => import("src/pages/content/AboutScouted"));
const BookAnExperienceFaq = React.lazy(() => import("src/pages/content/BookAnExperienceFaq"));
const Receipt = React.lazy(() => import("src/pages/user/Receipt"));
const ScoutReviews = React.lazy(() => import("src/pages/user/ScoutReviews"));
const Coupons = React.lazy(() => import("src/admin/Coupons"));
const Dashboard = React.lazy(() => import("src/admin/Dashboard"));
const Users = React.lazy(() => import("src/admin/Users"));
const Experiences = React.lazy(() => import("src/admin/Experiences"));
const Messages = React.lazy(() => import("src/admin/Messages"));
const RefundRequests = React.lazy(() => import("src/admin/RefundRequests"));
const Reviews = React.lazy(() => import("src/admin/Reviews"));
const Affiliates = React.lazy(() => import("src/admin/Affiliates"));
const BackgroundCheckReview = React.lazy(() => import("src/admin/BackgroundCheckReview"));
const Configuration = React.lazy(() => import("src/admin/Configuration"));
const TocAgreement = React.lazy(() => import("src/pages/user/TocAgreement"));
const TocBusAgreement = React.lazy(() => import("src/pages/user/TocBusAgreement"));
const Calendar = React.lazy(() => import("src/pages/experiences/Calendar"));
const TosAgreement = React.lazy(() => import("./pages/user/TosAgreement"));
const RegistrationProgress = React.lazy(() => import("./pages/user/RegistrationProgress"));
const AffiliateDashboard = React.lazy(() => import("src/pages/user/AffiliateDashboard"));
const ReAuth = React.lazy(() => import("./pages/connect/ReAuth"));
const BackgroundCancellation = React.lazy(() => import("./pages/payments/BackgroundCancellation"));
const BackgroundConfirmation = React.lazy(() => import("./pages/payments/BackgroundConfirmation"));
const BackgroundCompletion = React.lazy(() => import("./pages/background-checks/BackgroundCompletion"));
const BackgroundRequest = React.lazy(() => import("./pages/background-checks/BackgroundRequest"));
const BackgroundAuthorization = React.lazy(() => import("./pages/background-checks/BackgroundAuthorization"));
const MyMessage = React.lazy(() => import("./pages/user/Message"));
const MyMessages = React.lazy(() => import("./pages/user/Messages"));
const ContactUsThanks = React.lazy(() => import("./pages/footer-pages/ContactUsThanks"));
const Login = React.lazy(() => import("./pages/authentication/Login"));
const CreateAccount = React.lazy(() => import("./pages/authentication/CreateAccount"));
const Profile = React.lazy(() => import("src/pages/user/Profile"));
const FollowingScouts = React.lazy(() => import("./pages/user/FollowingScouts"));
const ExperienceDetails = React.lazy(() => import("./pages/experiences/ExperienceDetails"));
const EditExperience = React.lazy(() => import("./pages/experiences/EditExperience"));
const ResetVerification = React.lazy(() => import("./pages/authentication/ResetVerification"));
const CreateBusinessAccount = React.lazy(() => import("src/pages/authentication/CreateBusinessAccount"));
const ConfirmSignUp = React.lazy(() => import("./pages/authentication/ConfirmSignUp"));
const ResetPassword = React.lazy(() => import("./pages/authentication/ResetPassword"));
const ChangePassword = React.lazy(() => import("./pages/authentication/ChangePassword"));
const ChangeVerification = React.lazy(() => import("./pages/authentication/ChangeVerification"));
const SearchResults = React.lazy(() => import("./pages/SearchResults"));
const ContactUs = React.lazy(() => import("./pages/footer-pages/ContactUs"));
const Faq = React.lazy(() => import("./pages/footer-pages/Faq"));
const Support = React.lazy(() => import("./pages/footer-pages/Support"));
const WhyTrustScouted = React.lazy(() => import("./pages/footer-pages/WhyTrustScouted"));
const HowScoutedWorks = React.lazy(() => import("./pages/footer-pages/HowScoutedWorks"));
const MakeOnDemandIncome = React.lazy(() => import("./pages/footer-pages/MakeOnDemandIncome"));
const SignUp = React.lazy(() => import("./pages/footer-pages/SignUp"));
const SignUpBoise = React.lazy(() => import("./pages/footer-pages/SignUpBoise"));
const SignUpThanks = React.lazy(() => import("./pages/footer-pages/SignUpThanks"));
const WeAreHiring = React.lazy(() => import("./pages/footer-pages/WeAreHiring"));
const CancellationPolicy = React.lazy(() => import("./pages/footer-pages/CancellationPolicy"));
const Privacy = React.lazy(() => import("./pages/footer-pages/Privacy"));
const PaymentCancelled = React.lazy(() => import("./pages/payments/PaymentCancelled"));
const PaymentSuccess = React.lazy(() => import("./pages/payments/PaymentSuccess"));
const BusinessPaymentCancelled = React.lazy(() => import("./pages/payments/BusinessPaymentCancelled"));
const CreatedExperiences = React.lazy(() => import("./pages/user/CreatedExperiences"));
const BookedExperiences = React.lazy(() => import("./pages/user/BookedExperiences"));
const FavoriteExperiences = React.lazy(() => import("./pages/user/FavoriteExperiences"));
const Bookings = React.lazy(() => import("./pages/user/Bookings"));
const Receipts = React.lazy(() => import("./pages/user/Receipts"));
const Pricing = React.lazy(() => import("./pages/content/Pricing"));
const MAINTENANCE = false;

/**
 * Configure and set routes for scouted.com
 */
const App = () => {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    Hub.listen("datastore", async (hubData) => {
      const { event } = hubData.payload;
      if (event === "ready") {
        setReady(true);
      }
    });
  }, []);

  return MAINTENANCE
    ? <Router>
        <Switch>
          <Route component={Maintenance} />
        </Switch>
      </Router>
    : <Router>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route path="/sitemap.xml" exact />
            <Route path="/admin/dashboard" exact component={Dashboard}></Route>
            <Route path="/admin/refund-requests" exact component={RefundRequests}></Route>
            <Route path="/admin/background-check-reviews" exact component={BackgroundCheckReview}></Route>
            <Route path="/admin/users" exact component={Users}></Route>
            <Route path="/reviews/:id" component={ScoutReviews} />
            <Route path="/bookings-report" exact component={BookingLedger} />
            <Route path="/admin/experiences" exact component={Experiences}></Route>
            <Route path="/admin/messages" exact component={Messages}></Route>
            <Route path="/admin/Reviews" exact component={Reviews}></Route>
            <Route path="/admin/affiliates" exact component={Affiliates}></Route>
            <Route path="/admin/configuration" exact component={Configuration}></Route>
            <Route path="/admin/coupons" exact component={Coupons}></Route>
            <Route exact path="/" component={LandingPage} />
            <Route path="/blog/:slug" component={Post} />
            <Route path="/blog" component={Posts} />
            <Route path="/search/:first?/:second?/:third?" component={SearchResults} />
            <Route path="/login" component={Login} />{" "}
            <Route path="/create-business-account" component={CreateBusinessAccount} />
            <Route path="/create-account" component={CreateAccount} />
            <Route path="/affiliate-dashboard" component={AffiliateDashboard} />
            <Route path="/confirm-signup" component={ConfirmSignUp} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/change-password" component={ChangePassword} />
            <Route path="/change-password-verification" component={ChangeVerification} />
            <Route path="/reset-verification" component={ResetVerification} />
            <Route path="/profile/:id" component={Profile} />
            <Route path="/following-scouted-local-experts" component={FollowingScouts} />
            <Route path="/messages" component={MyMessages} />
            <Route path="/message/:id" component={MyMessage} />
            <Route path="/experience/:username/:exptitle" exact component={ExperienceDetails} />
            <Route path="/edit/:id" component={EditExperience} />
            <Route path="/experience/:id/preview" exact component={EditExperience} />
            <Route path="/booked-experiences" component={BookedExperiences} />
            <Route path="/bookings" component={Bookings} />
            <Route path="/receipts" component={Receipts} />
            <Route path="/receipt/:id" component={Receipt} />
            <Route path="/apply" component={ContactUs} />
            <Route path="/registration-progress" component={RegistrationProgress} />
            <Route path="/created-experiences" component={CreatedExperiences} />
            <Route path="/payment-cancelled" component={PaymentCancelled} />
            <Route path="/payment-success" component={PaymentSuccess} />
            <Route path="/business/subscription-payment-cancelled" component={BusinessPaymentCancelled} />
            <Route path="/background-check/authorization" component={BackgroundAuthorization} />
            <Route path="/background-check/background-request" component={BackgroundRequest} />
            <Route path="/background-check/background-completion" component={BackgroundCompletion} />
            <Route path="/be-a-scout/subscription-payment-cancelled" component={BackgroundCancellation} />
            <Route path="/background-check/background-confirmation" component={BackgroundConfirmation} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/terms-and-conditions" component={TocAgreement} />
            <Route path="/business-listing-terms-and-conditions" component={TocBusAgreement} />
            <Route path="/cancellation-policy" component={CancellationPolicy} />
            <Route path="/customer-service" component={ContactUs} />
            <Route path="/we-are-hiring" component={WeAreHiring} />
            <Route path="/make-on-demand-income" component={MakeOnDemandIncome} />
            <Route path="/signup/:id" component={SignUp} />
            <Route path="/signup" component={SignUp} />
            <Route path="/signupboise" component={SignUpBoise} />
            <Route path="/signup-thanks" component={SignUpThanks} />
            <Route path="/support" component={Support} />
            <Route path="/faq" component={Faq} />
            <Route path="/contact-scouted" component={ContactUs} />
            <Route path="/contact-scouted-thanks" component={ContactUsThanks} />
            <Route path="/how-scouted-works" component={HowScoutedWorks} />
            <Route path="/why-trust-scouted" component={WhyTrustScouted} />
            <Route path="/reauth" component={ReAuth} />
            <Route path="/tos-agreement" component={TosAgreement} />
            <Route path="/calendar" component={Calendar} />
            <Route path="/favorite-experiences" component={FavoriteExperiences} />
            <Route path="/error" component={Error} />
            <Route path="/third-party-error" component={ThirdPartyError} />
            <Route path="/about/scouted" component={AboutScouted} />
            <Route path="/book-an-experience/faq" component={BookAnExperienceFaq} />
            <Route path="/book-an-experience/how-it-works" component={BookAnExperienceHowItWorks} />
            <Redirect path="/be-a-scout" to="/be-a-scout/purchase-options" exact />
            <Route path="/be-a-scout/faq" component={BeAScoutFaq} />
            <Route path="/be-a-scout/how-it-works" component={BeAScoutHowItWorks} />
            <Route path="/be-a-scout/purchase-options" component={BeAScoutPurchaseOptions} />
            <Route path="/be-a-scout/purchase-confirmation" component={BeAScoutPurchaseConfirmation} />
            <Route path="/be-a-scout/purchase-features" component={BeAScoutPurchaseFeatures} />
            <Route path="/be-a-scout/affiliate-program" component={BeAScoutAffiliate} />
            <Redirect path="/promote-your-business" to="/business/purchase-options" />
            <Route path="/business/purchase-options" component={BusinessPurchaseOptions} />
            <Route path="/business/purchase-confirmation" component={BusinessPurchaseConfirmation} />
            <Route path="/business/purchase-features" component={BusinessPurchaseFeatures} />
            <Route path="/business/partnership-program" component={BusinessPartnershipProgram} />
            <Route path="/pricing" component={Pricing} />
            <Route path="/:username" exact component={SearchResults} />
            <Route path="/404" component={NotFound} />
            <Redirect to="/404" />
          </Switch>
        </Suspense>
      </Router>;
}

export default App;
