import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import { userActions, bookingActions } from "src/db/dbActions";
import { authService, contactService } from "src/api/apiActions";
import { LOGO_TEXT } from "src/constants";
import { DataStore } from "@aws-amplify/datastore";
import { Booking, User } from "src/models";
import { purgeData } from "src/utils";
import { ListGroup } from "react-bootstrap";
import { scoutedProfileComplete } from "src/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "src/styles/Header.css";

class Header extends React.Component {
  constructor() {
    super();
    this.bookingSub = undefined;
    this.state = {
      results: [],
      right: false,
      showAbout: false,
      bookings: [],
      newScoutMsgs: [],
      newScoutedMsgs: [],
      user: null,
    };
  }

  componentDidMount() {
    this.mounted = true;
    document.getElementById("menu-icon").addEventListener("click", function (e) {
      if (e.target.classList.contains("hamburger-toggle")) {
        e.target.children[0].classList.toggle("active");
      }
    });

    authService
      .isAuthenticated()
      .then((res) => {
        userActions.getCurrentUser({ email: res.attributes.email }).then((currUser) => {
          this.setState({ user: currUser[0] });
          bookingActions
            .getAllBookings()
            .then((bookings) => {
              this.setState({
                bookings: bookings
                  .sort((a, b) => new Date(b._lastChangedAt) - new Date(a._lastChangedAt))
                  .filter((book) => book.scoutedId === currUser[0].id),
                newScoutMsgs: bookings.filter(
                  (book) =>
                    currUser &&
                    currUser.length > 0 &&
                    book.experience.scoutId === currUser[0].id &&
                    book.messaging &&
                    book.messaging.messages &&
                    book.messaging.messages.length > 0 &&
                    !book.messaging.seenByScout
                ),
                newScoutedMsgs: bookings.filter(
                  (book) =>
                    currUser &&
                    currUser.length > 0 &&
                    book.scoutedId === currUser[0].id &&
                    book.messaging &&
                    book.messaging.messages &&
                    book.messaging.messages.length > 0 &&
                    !book.messaging.seenByScouted
                ),
              });
            })
            .catch((e) => e);
          this.bookingSub = DataStore.observe(Booking).subscribe((b) => {
            bookingActions.getAllBookings().then((bookings) =>
              this.setState({
                bookings: bookings
                  .sort((a, b) => new Date(b._lastChangedAt) - new Date(a._lastChangedAt))
                  .filter((book) => book.scoutedId === currUser[0].id),
                newScoutMsgs: bookings.filter(
                  (book) =>
                    currUser &&
                    currUser.length > 0 &&
                    book.experience.scoutId === currUser[0].id &&
                    book.messaging &&
                    book.messaging.messages &&
                    book.messaging.messages.length > 0 &&
                    !book.messaging.seenByScout
                ),
                newScoutedMsgs: bookings.filter(
                  (book) =>
                    currUser &&
                    currUser.length > 0 &&
                    book.scoutedId === currUser[0].id &&
                    book.messaging &&
                    book.messaging.messages &&
                    book.messaging.messages.length > 0 &&
                    !book.messaging.seenByScouted
                ),
              })
            );
          });
          this.userSub = DataStore.observe(User).subscribe((u) => {
            if (u && currUser && u.element.id === currUser[0].id) {
              this.setState({ user: u.element });
            }
          });
        });
      })
      .catch((e) => e);
  }

  componentWillUnmount() {
    if (this.bookingSub) {
      this.bookingSub.unsubscribe();
    }
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }

  /**
   * Renders Front End Menu
   */
  renderFrontMenu() {
    return (
      <>
        <Link to="/login" id="menu_login">
          <NavDropdown.Item as="div" className="pt-3">
            <span className="top-bar p-0">Sign In</span>
          </NavDropdown.Item>
        </Link>

        <Link to="/create-account">
          <NavDropdown.Item as="div">
            <span className="top-bar p-0">Create Account</span>
          </NavDropdown.Item>
        </Link>

        <NavDropdown.Divider />

        <Link to="/be-a-scout">
          <NavDropdown.Item as="div">
            <span className="top-bar p-0">Become a Scout</span>
          </NavDropdown.Item>
        </Link>

        <Link to="/promote-your-business">
          <NavDropdown.Item as="div">
            <span className="top-bar p-0">Promote Your Business</span>
          </NavDropdown.Item>
        </Link>

        <NavDropdown.Divider />

        <Link to="/contact-scouted">
          <NavDropdown.Item as="div" className="pb-3">
            <span className="top-bar p-0">Help</span>
          </NavDropdown.Item>
        </Link>
      </>
    );
  }

  /**
   * Renders Profile Menu
   */
  renderMenu() {
    const { user } = this.state;
    return (
      <>
        <Link
          to={
            (!user.stripeId && user.stripeId === "" && user.isBusiness) || !scoutedProfileComplete(user)
              ? `/profile/${user.id}`
              : "/registration-progress"
          }
          onClick={() => {
            if (!scoutedProfileComplete(user) && (!user.stripeId || user.stripeId === "")) {
              return;
            } else if (!user.isBusiness && !user.isInterested) {
              contactService.scoutInterest({
                name: `${user.firstName} ${user.lastName}`,
                email: user.email,
                phone: user.phone,
              });
              userActions
                .updateUser(Object.assign({}, user, { isInterested: true }))
                .then(() => (window.location = "/registration-progress"));
            }
          }}
          className="list-group-item-action top-bar pe-0"
          aria-current="true"
        >
          <NavDropdown.Item
            as="div"
            className="d-flex align-items-center pt-3 ps-2 ps-md-0 top-bar"
            style={{ border: "solid 0px black" }}
          >
            <div className="d-flex w-100 justify-content-between align-items-center ps-2 ps-md-3">
              <p className="menu-small-title top-bar" style={{ color: "rgb(36, 90, 188)", fontWeight: "900" }}>
                {user.firstName}
              </p>
            </div>
          </NavDropdown.Item>
        </Link>

        <Link to={`/profile/${user.id}`} aria-current="true">
          <NavDropdown.Item as="div">
            <div className="list-group-item-action top-bar">Profile</div>
          </NavDropdown.Item>
        </Link>

        {!user.isBusiness && (
          <>
            <Link to="/booked-experiences" className="list-group-item-action" aria-current="true">
              <NavDropdown.Item as="div">
                <div className="list-group-item-action top-bar">Bookings</div>
              </NavDropdown.Item>
            </Link>
          </>
        )}

        <Link to="/messages" className="list-group-item-action" aria-current="true">
          <NavDropdown.Item as="div">
            <div className="list-group-item-action top-bar">Inbox</div>
          </NavDropdown.Item>
        </Link>

        {!user.isBusiness && (
          <>
            <Link to="/following-scouted-local-experts" className="list-group-item-action">
              <NavDropdown.Item as="div">
                <div className="list-group-item-action top-bar">Follows</div>
              </NavDropdown.Item>
            </Link>

            <Link to="/favorite-experiences" className="list-group-item-action">
              <NavDropdown.Item as="div">
                <div className="list-group-item-action top-bar">Favorites</div>
              </NavDropdown.Item>
            </Link>
          </>
        )}
        {user.isScout && (
          <>
            <NavDropdown.Divider />
            <NavDropdown.Item className="disabled">
              <div className="d-flex w-100 justify-content-between">
                <span className="menu-small-title top-bar " style={{ color: "#212529", fontWeight: "900" }}>
                  Scout Management
                </span>
              </div>
            </NavDropdown.Item>
          </>
        )}
        {user.isBusiness && (
          <>
            <NavDropdown.Divider />
            <NavDropdown.Item className="disabled">
              <div className="d-flex w-100 justify-content-between">
                <span className="menu-small-title top-bar" style={{ color: "#212529", fontWeight: "900" }}>
                  Business Management
                </span>
              </div>
            </NavDropdown.Item>
          </>
        )}
        {(user.isScout || user.isBusiness) && (
          <>
            <Link to="/created-experiences" className="list-group-item-action">
              <NavDropdown.Item as="div">
                <div className="list-group-item-action top-bar">Manage Experiences</div>
              </NavDropdown.Item>
            </Link>

            <Link to="/bookings" className="list-group-item-action">
              <NavDropdown.Item as="div">
                <div className="list-group-item-action top-bar">Manage Bookings</div>
              </NavDropdown.Item>
            </Link>

            <Link to="/bookings-report" className="list-group-item-action">
              <NavDropdown.Item as="div">
                <div className="list-group-item-action top-bar">Bookings Report</div>
              </NavDropdown.Item>
            </Link>
          </>
        )}
        <NavDropdown.Divider />

        <Link to="/contact-scouted" className="list-group-item-action">
          <NavDropdown.Item as="div">
            <div className="list-group-item-action top-bar">Help</div>
          </NavDropdown.Item>
        </Link>

        <Link
          className="list-group-item-action top-bar d-flex w-100"
          style={{ color: "#212529" }}
          onClick={() => {
            authService
              .signOut()
              .then(() => purgeData().finally(() => (window.location = "/")))
              .catch(() => {});
          }}
        >
          <NavDropdown.Item className="pb-3">
            <div className="list-group-item-action top-bar">Log out</div>
          </NavDropdown.Item>
        </Link>
      </>
    );
  }

  /**
   * THE ADMIN MENU
   */
  renderAdmin() {
    const { user } = this.state;
    return (
      <Row className="mega-content px-4 w-100">
        <Container className="container-fluid">
          <Row className="row text-start">
            <Col sm={6} md={3} className="ps-4">
              <ListGroup className="borderless pt-1 pt-md-4">
                <h5 className="pb-1 pt-0 info-header">General</h5>
                {user.isAdmin && (
                  <Link to="/admin/dashboard" className="list-group-item-action" aria-current="true">
                    <ListGroup.Item className="pb-0">
                      <p className="menu-small-title">Dashboard</p>
                    </ListGroup.Item>
                  </Link>
                )}
                {(user.isAdmin || user.isFinanceAdmin) && (
                  <Link to="/admin/configuration" className="list-group-item-action" aria-current="true">
                    <ListGroup.Item className="pb-0">
                      <p className="menu-small-title">Configuration</p>
                    </ListGroup.Item>
                  </Link>
                )}
                {(user.isAdmin || user.isCsAdmin) && (
                  <Link to="/admin/affiliates" className="list-group-item-action" aria-current="true">
                    <ListGroup.Item className="pb-0">
                      <p className="menu-small-title">Affiliates</p>
                    </ListGroup.Item>
                  </Link>
                )}
                {(user.isAdmin || user.isFinanceAdmin) && (
                  <Link to="/admin/coupons" className="list-group-item-action" aria-current="true">
                    <ListGroup.Item className="pb-0">
                      <p className="menu-small-title">Coupons</p>
                    </ListGroup.Item>
                  </Link>
                )}
              </ListGroup>
            </Col>
            <Col sm={6} md={3} className="ps-4">
              <ListGroup className="borderless pt-1 pt-md-4">
                <h5 className="pb-1 pt-0 info-header">Site Data</h5>
                {(user.isAdmin || user.isCsAdmin) && (
                  <Link to="/admin/users" className="list-group-item-action" aria-current="true">
                    <ListGroup.Item className="pb-0">
                      <p className="menu-small-title">All Users</p>
                    </ListGroup.Item>
                  </Link>
                )}
                {(user.isAdmin || user.isCsAdmin) && (
                  <Link to="/admin/experiences" className="list-group-item-action" aria-current="true">
                    <ListGroup.Item className="pb-0">
                      <p className="menu-small-title">Experiences</p>
                    </ListGroup.Item>
                  </Link>
                )}
                {(user.isAdmin || user.isCsAdmin) && (
                  <Link to="/admin/messages" className="list-group-item-action" aria-current="true">
                    <ListGroup.Item className="pb-0">
                      <p className="menu-small-title">Messages</p>
                    </ListGroup.Item>
                  </Link>
                )}
                {(user.isAdmin || user.isCsAdmin) && (
                  <Link to="/admin/reviews" className="list-group-item-action" aria-current="true">
                    <ListGroup.Item className="pb-0">
                      <p className="menu-small-title">Reviews</p>
                    </ListGroup.Item>
                  </Link>
                )}
              </ListGroup>
            </Col>
            <Col sm={6} md={3} className="ps-4">
              <ListGroup className="borderless pt-1 pt-md-4">
                <h5 className="pb-1 pt-0 info-header">Approvals</h5>
                {(user.isAdmin || user.isFinanceAdmin) && (
                  <Link to="/admin/refund-requests" className="list-group-item-action" aria-current="true">
                    <ListGroup.Item className="pb-0">
                      <p className="menu-small-title">Refund Requests</p>
                    </ListGroup.Item>
                  </Link>
                )}
                {user.isAdmin && (
                  <Link to="/admin/background-check-reviews" className="list-group-item-action" aria-current="true">
                    <ListGroup.Item className="pb-0">
                      <p className="menu-small-title">Background Checks</p>
                    </ListGroup.Item>
                  </Link>
                )}
              </ListGroup>
            </Col>
            <Col sm={6} md={3} className="ps-4">
              <ListGroup className="borderless pt-1 pt-md-4">
                <h5 className="pb-1 pt-0 info-header">Requiring Attention</h5>
                {(user.isAdmin || user.isCsAdmin) && (
                  <Link to="/admin/dashboard" className="list-group-item-action" aria-current="true">
                    <ListGroup.Item className="pb-0">
                      <p className="menu-small-title">Flagged Items</p>
                    </ListGroup.Item>
                  </Link>
                )}
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </Row>
    );
  }
  /**
   * THE MAIN TOP MENU MENU
   */
  render() {
    const { user } = this.state;
    return (
      <Navbar fixed={window.location.pathname.includes("/edit") ? "top" : null} bg="light" variant="light">
        <Navbar.Brand className="me-auto">
          <Link to="/" className="me-auto">
            <Image alt="Scouted LLC Logo" className="img-fluid logo" src={LOGO_TEXT} />
          </Link>
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="new-navbar"
          id="menu-icon"
          className="navbar-toggle collapsed hamburger hamburger-toggle pe-3"
        ></Navbar.Toggle>

        <Navbar.Collapse id="new-navbar" className="pe-0">
          <Nav className="ms-auto dropdown-menu-left me-1">
            <Nav.Item className="d-none d-sm-flex align-items-center justify-content-end">
              <Link className="nav-link top-bar" to="/search">
                <span className="me-1">Experiences</span>
              </Link>
            </Nav.Item>

            <Nav.Item className="d-flex d-sm-none align-items-center justify-content-end">
              <Link className="nav-link top-bar" to="/search">
                <span className="icon-search-large me-0 me-md-0 pe-2"></span>
              </Link>
            </Nav.Item>

            {/**
             * HOLDING THE ADMIN BAR
             */}
            {user && user.isAdmin && (
              <Dropdown className="dropdown dropdown-mega position-static d-flex align-items-center">
                <Dropdown.Toggle
                  as={Nav.Item}
                  style={{
                    outline: " none !important",
                    outlineOffset: "none !important",
                    boxShadow: "none !important",
                  }}
                  className="navbar-btn bg-light nav-link dropdown-toggle top-bar px-0 px-sm-0 px-md-0 px-lg-2 menu-cursor"
                >
                  Admin
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu shadow w-100 pb-4">{this.renderAdmin()}</Dropdown.Menu>
              </Dropdown>
            )}
            {/**
             * HOLDING THE PROFILE MENU
             */}
            {user && (
              <NavDropdown
                className="top-bar ms-0 pb-0"
                title={
                  <div
                    className="align-top d-flex align-items-center"
                    style={{ border: "1px black solid", borderRadius: "25px", backgroundColor: "#f0f0f0" }}
                  >
                    <span className="icon-header-bars me-0 me-md-0 ps-3 pe-1"></span>{" "}
                    <span className="icon-circle-user me-0 me-md-0 ps-2 pe-3"></span>{" "}
                  </div>
                }
              >
                {this.renderMenu()}
              </NavDropdown>
            )}
            {/**
             * HOLDING THE FRONT END MENU
             */}
            {!user && (
              <NavDropdown
                className="top-bar ms-3 ms-md-0 pb-3 pb-md-0"
                title={
                  <div
                    className="align-top d-flex align-items-center"
                    style={{ border: "1px black solid", borderRadius: "25px" }}
                  >
                    <span className="icon-header-bars me-0 me-md-0 ps-3 pe-1"></span>{" "}
                    <span className="icon-circle-user me-0 me-md-0 ps-2 pe-3"></span>{" "}
                  </div>
                }
              >
                {this.renderFrontMenu()}
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Header;
